<template>
    <conta-form acao="INSERIR" @salvar="inserir($event)" :cancelar="cancelar" :erros="erros"></conta-form>
</template>

<script>
import ContaForm from './ContaForm';
import ContasService from './services';

export default {
    components: {
        ContaForm,
    },

    data() {
        return {
            erros: [],
        };
    },

    computed: {},

    methods: {
        cancelar() {
            this.$router.push({ name: `Pagamentos_Contas` });
        },

        inserir(contaInserida) {
            this.$store.dispatch('addRequest');
            let contaInseridaDto = {
                descricao: contaInserida.descricao,
                banco: contaInserida.banco,
                objetoIntegracao: contaInserida.objetoIntegracao,
            };
            ContasService.inserir(contaInseridaDto).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Contas',
                        detail: 'Conta inserida com sucesso',
                        life: 3000,
                    });
                    this.toDetalhar(response.data);
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toDetalhar(contaId) {
            this.$store.dispatch('setAtualizar', true);
            this.$router.push({
                name: `Pagamentos_Contas_Detalhar`,
                params: {
                    id: contaId,
                },
            });
        },
    },
};
</script>
